import React from 'react'

import {
  ConditionalProductLink,
  Image,
  ProductActionButton,
  ProductBadges,
  ProductRating,
} from '../..'
import ProductPrices from '../PriceInfo/ProductPrices'
import ProductPriceHint from '../PriceInfo/ProductPriceHint'
import ProductLink from '../Link/ProductLink'

import { getTierIconClass, useFeatureFlags } from '../../../utils'

export default function ProductItemGrid({
  productData,
  ratingSummary,
  trackingData,
}) {
  const {
    images = '',
    manufacturer_name = '',
    url = '',
    name = '',
    config_variable_a = '',
    badge = '',
    tiericon = '',
    produkt_linie = '',
    small_image = '',
  } = productData
  const tierIconClass = getTierIconClass(tiericon)
  const imgUrl = small_image ? small_image : images[0]
  const { isFeatureEnabled } = useFeatureFlags()

  return (
    <>
      <form
        className="product-item__form product-item-grid"
        action=""
        method="post"
      >
        <div className="products-grid-header font-size-m font-weight-semi-bold p-3">
          <span>{manufacturer_name}</span>
          <span> {produkt_linie}</span>
        </div>
        <div className="row mx-0">
          <div className="col col-5">
            {isFeatureEnabled('product.badges') && (
              <ProductBadges badge={badge} />
            )}
            <ConditionalProductLink
              href={url}
              className="product-item__image position-relative"
            >
              <Image srcFull={imgUrl} />
            </ConditionalProductLink>
          </div>
          <div className="col col-7">
            <div className="truncate-text mb-1">
              <ProductLink href={url}>
                <span>{name}</span> <span>{config_variable_a}</span>
              </ProductLink>
            </div>

            <div className="product-price-container">
              <ProductPrices
                productData={productData}
                classSpecialPrice="font-size-m"
                classRegularPrice="d-inline-block ml-2"
                classUnitPrice="font-size-xxs d-block"
                forPage="grid"
              />
              <ProductPriceHint className="mb-2 font-size-xxs" />
            </div>
            {tierIconClass.map((iconClass, index) => {
              return <i key={index} className={iconClass} />
            })}
            {ratingSummary && (
              <ProductRating
                productData={productData}
                ratingSummary={ratingSummary}
              />
            )}
          </div>
        </div>
        <div className="px-3 pt-2 pb-3 p-md-3">
          <ProductActionButton
            productData={productData}
            trackingData={trackingData}
            className="w-100"
          />
        </div>
      </form>
    </>
  )
}
